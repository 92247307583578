.left-portion {
    /* height: 200px; */
    padding: 5px;
}

.right-portion {
    height: 200px;
    padding: 5px;
}

.left-section {
    /* padding: 5px; */
    height: 100vh;
    width: 100%;
    background: #F0EDF0
}

.right-section {
    padding: 5px;
    height: 100vh;
    width: 100%;
    overflow: scroll;
    background: #F0EDF0;
}

.in-house-section {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #E5F7F6;
    border: none;
}

.switch-btn {
    border-radius: 20px;
    background: #01ADA8 !important;
}

.logo-section>img {
    width: 118px;
    height: 118px;
    overflow: hidden;
    border-radius: 90%;
}

.logo-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 43px;
}

.company-name {
    margin-top: 24px;
}

.section-separator {
    margin-top: 30px;
    border-bottom: 1px solid rgb(181, 173, 173);
}

.status-section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-name {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.normal-color {
    height: 8px;
    width: 8px;
    border-radius: 1px;
    background: #01ADA8;
}

.energy-card {
    padding: 10px;
}

.energy-content {
    padding: 5px;
    height: 68px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 1px 14px 1px rgba(0, 0, 0, 0.59);
    -webkit-box-shadow: 0px 1px 14px 1px rgba(0, 0, 0, 0.59);
    -moz-box-shadow: 0px 1px 14px 1px rgba(0, 0, 0, 0.59);
}

.energy-icon {
    padding: 10px;
}

.value-text {
    text-align: right;
    color: #0A5C59;
    font-weight: bold;
}

.right-section {
    padding: 20px;
}

.meter-card {
    padding: 10px;
}

.card-section {
    padding: 10px;
    background: #F2F6F6;
    /* background: red; */
    /* height: 135px; */
}

.card-secondary:hover {
    border: 1px solid #00e1fb;
    cursor: pointer;
}

.card-top-section {
    display: flex;
    justify-content: space-between;
}

.status-text {
    background: #01ADA8;
    color: white;
    padding: 2px;
}

.card-bottom-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.card-bottom-text-section {
    display: flex;
    justify-content: end;
}

.card-bottom-text {
    padding: 5px;
    border: 1px solid #CDCDCD;
}

.header-texts {
    display: flex;
    justify-content: space-between;
}

.card-header-section {
    background: #01ADA8;
    padding: 5px;
    color: white;
}