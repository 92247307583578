.google-map {
    overflow: hidden;
    height: 0;
    padding-bottom: 60%;
    position: relative;
}

.google-map iframe {
    border: 0;
    padding: 5px;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
}

.status,
.alarm,
.distribution {
    min-height: 265px;
    width: 100%;
    /* background: skyblue; */
    padding: 5px;
}

.status {}

.card-portion {
    padding: 10px;
    background: rgb(240, 237, 240);
    min-height: 265px;
    box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 9px -1px rgba(0, 0, 0, 0.75);
}


/* .custom-gap,
.col {
    margin-bottom: 20px;
} */

.title-color {
    width: 5px;
    height: 15px;
    background-color: #01ADA8;
}

.title-text {
    margin-top: -3px;
    font-weight: bold;
}

.title-portion {
    display: flex;
    gap: 11px;
}

.icon-background {
    height: 54px;
    width: 54px;
    background: #E1F0EF;
    border-radius: 5px;
}

.blue-text {
    color: #0A5C59;
    font-weight: bold;
}

.divider-line {
    border-bottom: 1px solid #b0adad;
}

.energy-statistics {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    gap: 4rem;
}

.section-size {
    /* height: 67px; */
}

.alarm-table {
    width: 100%;
    background: #01ADA8;
    color: white;
    border: none;
}

.alarm-table>tr>th {
    border: none;
}

.alarm-table tr,
.alarm-table th {
    border: none;
    font-weight: normal;
}